<template>
  <!-- filter area -->
  <div class="p-fluid p-grid p-jc-end">
    <!-- right -->
    <div class="p-col-11 p-sm-3 print-hidden" v-if="userLevel == 'AC'">
      <Dropdown
        v-model="areaId"
        :options="areas"
        optionLabel="areaName"
        optionValue="codeId"
        placeholder="กรองข้อมูล พื้นที่"
        :filter="true"
        @change="fetchData"
      />
    </div>
    <div class="p-col-1 p-fluid">
      <Button icon="pi pi-print" @click="print" />
    </div>
  </div>
  <!-- / filter area -->
  <Fieldset>
    <template #legend>
      <i class="pi pi-chart-bar" style="fontsize: 2rem"></i>
      ความสามารถในการทำงานของผู้สูงอายุแต่ละช่วงอายุ
    </template>
    <div ref="chartdiv" style="height: 500px"></div>
    <div class="p-grid">
      <div
        class="p-col-12 p-sm-5"
        v-for="(text, link) in menus"
        :key="link"
        style="margin-left: 75px"
      >
        <router-link :to="link">
          <h5><i class="pi pi-chart-bar"></i> {{ text }}</h5>
        </router-link>
      </div>
    </div>
  </Fieldset>
  <MoveToTopPage />
</template>

<script>
import * as _ from 'lodash'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import { mixins } from '../Mixins'

import MoveToTopPage from '../../components/MovetoTop'

// Themes begin
am4core.useTheme(am4themes_animated)
// Themes end

export default {
  mixins: [mixins],
  layout: 'Private',
  data() {
    return {
      menus: {
        '/analytic/info/elderly': 'วิเคราะห์ข้อมูลพื้นฐานของผู้สูงอายุ',
        '/analytic/info/healthcondition': 'วิเคราะห์ภาวะสุขภาพ และโรคเรื้อรัง',
        '/analytic/info/bodypaincondition': 'ภาวะความเจ็บปวดของร่างกาย',
        '/analytic/info/tmse': 'สมรรถภาพสมอง (TMSE) และภาวะเครียด(ST5)',
        '/analytic/info/econ': 'เศรษฐานะ (ECON)',
        '/analytic/info/occupation': 'อาชีพ',
        '/analytic/info/tenvaliablework': '10 ตัวแปรที่ส่งผลกับความสามารถในการทำงาน',
        '/analytic/info/careervaliablework': 'ตัวแปรที่ส่งผลต่อความสามารถในการทำงานของแต่ละอาชีพ',
        //'/analytic/info/tenvaliableworkcarrea': '10 ตัวแปรที่ส่งผลกับความสามารถในการทำงานของแต่ละอาชีพ'
        // '/analytic/wordcloud/occupation/other': 'อาชีพอื่นๆ นอกเหนือจาก 7 อาชีพ',
        // '/analytic/wordcloud/occupation/non': 'เหตุผลที่ไม่เลือก 7 อาชีพหลัก'
      },
      areaId: null,
      series: {
        index: null
      }
    }
  },
  watch: {
    series: {
      handler(newVal, oldVal) {
        console.log('newVal ::==', newVal)
        const { index } = newVal
        this.renderStackGraph(index)
      },
      deep: true
    }
  },
  computed: {
    userLevel() {
      const { codeId, userLevel } = this.$store.getters['oauth/session']
      return userLevel
    },
    userArea() {
      const { codeId, userLevel, area } = this.$store.getters['oauth/session']
      if (userLevel == 'AC') {
        return this.areaId == 'ALL' ? null : this.areaId
      } else {
        return area?.codeId
      }
    },
    areas() {
      return this.$store.getters['area/getAreasHasAllLabel']
    }
  },
  async created() {
    try {
      await this.$store.dispatch('area/fetchAreasOrderNameTh')
      this.fetchData()
    } catch (error) {
      this.$toast.add({
        severity: 'error',
        summary: 'เกิดข้อผิดพลาดจากระบบ',
        detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
        life: 3000
      })
    }
  },
  methods: {
    async fetchData() {
      const data = await this.$store.dispatch(
        'pivot/fetchGraphSeriesAnalytise',
        {
          url: 'INDEX',
          areaId: this.userArea
        }
      )
      console.log('data ::==', data)
      this.series = data
    },
    renderStackGraph(_data) {
      var chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)
      chart.colors.list = [
        am4core.color('#C70039'),
        am4core.color('#299F1F'),
        am4core.color('#761474')
      ]

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'top'
      chart.legend.paddingBottom = 20
      chart.legend.labels.template.maxWidth = 95

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      xAxis.dataFields.category = 'category'
      xAxis.renderer.cellStartLocation = 0.1
      xAxis.renderer.cellEndLocation = 0.9
      xAxis.renderer.grid.template.location = 0
      //xAxis.title.text = 'ช่วงอายุ ใส่ปีหลังอายุ'

      var yAxis = chart.yAxes.push(new am4charts.ValueAxis())
      yAxis.min = 0
      yAxis.title.text = 'จำนวนผู้สูงอายุ (คน)'

      function createSeries(value, name) {
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.valueY = value
        series.dataFields.categoryX = 'category'
        series.name = name

        series.events.on('hidden', arrangeColumns)
        series.events.on('shown', arrangeColumns)

        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

        var bullet = series.bullets.push(new am4charts.LabelBullet())
        bullet.interactionsEnabled = false
        bullet.dy = 30
        bullet.label.text = '{valueY}'
        bullet.label.fill = am4core.color('#000000')
        bullet.label.dy = -40

        return series
      }
      console.log('_data ::==', _data)
      chart.data = [
        {
          category: '60 - 65 ปี',
          first: _data?.count11,
          second: _data?.count12,
          third: _data?.count13
        },
        {
          category: '66 - 70 ปี',
          first: _data?.count21,
          second: _data?.count22,
          third: _data?.count23
        },
        {
          category: '> 70 ปี',
          first: _data?.count31,
          second: _data?.count32,
          third: _data?.count33
        }
      ]

      createSeries('first', 'มีปัญหาในการทำงาน')
      createSeries('second', 'สามารถทำงานได้ปานกลาง')
      createSeries('third', 'สามารถทำงานได้ดี')

      function arrangeColumns() {
        var series = chart.series.getIndex(0)

        var w =
          1 -
          xAxis.renderer.cellStartLocation -
          (1 - xAxis.renderer.cellEndLocation)
        if (series.dataItems.length > 1) {
          var x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX')
          var x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX')
          var delta = ((x1 - x0) / chart.series.length) * w
          if (am4core.isNumber(delta)) {
            var middle = chart.series.length / 2

            var newIndex = 0
            chart.series.each(function (series) {
              if (!series.isHidden && !series.isHiding) {
                series.dummyData = newIndex
                newIndex++
              } else {
                series.dummyData = chart.series.indexOf(series)
              }
            })
            var visibleCount = newIndex
            var newMiddle = visibleCount / 2

            chart.series.each(function (series) {
              var trueIndex = chart.series.indexOf(series)
              var newIndex = series.dummyData

              var dx = (newIndex - trueIndex + middle - newMiddle) * delta

              series.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
              series.bulletsContainer.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
            })
          }
        }
      }
    }
  },
  components: {
    MoveToTopPage
  }
}
</script>

<style lang="scss" scoped>
</style>